import React, { useState, useRef } from 'react';
import styles from './SidebarMobileMenu.module.scss';
import { SidebarIcon } from '@ui/molecules/SidebarIcon/SidebarIcon';
import { SidebarMobileMenuProps } from './SidebarMobileMenu.interface';
import { Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { ClickAwayListener, Grow, MenuList, Paper, Popper } from '@mui/material';

export const SidebarMobileMenu = ({ items, routerAsPath }: SidebarMobileMenuProps): JSX.Element => {
  const activeMenuItem = items.find(({ appPath }) => routerAsPath === appPath);

  const [openSidebarMobileMenu, setOpenSidebarMobileMenu] = useState(false);
  const anchorSidebarMobileMenuRef = useRef<HTMLButtonElement>(null);
  const sidebarMobileMenuToggle = () => {
    setOpenSidebarMobileMenu((prevOpen) => !prevOpen);
  };
  const sidebarMobileMenuClose = (event: any) => {
    if (
      anchorSidebarMobileMenuRef.current &&
      anchorSidebarMobileMenuRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpenSidebarMobileMenu(false);
  };

  return (
    <div className={styles.accountSidebarMobileMenu}>
      <Button
        className={styles.accountSidebarMobileMenuBtn}
        color='primary'
        variant='outlined'
        fullWidth
        ref={anchorSidebarMobileMenuRef}
        aria-controls={openSidebarMobileMenu ? 'sidebar-mobile-menu' : undefined}
        aria-haspopup='true'
        onClick={sidebarMobileMenuToggle}
      >
        {activeMenuItem ? (
          <SidebarIcon
            icon={activeMenuItem.icon}
            label={activeMenuItem.label}
            appPath={activeMenuItem.appPath}
            routerAsPath={routerAsPath}
            onClick={(event) => event.preventDefault()}
          />
        ) : (
          <span>Sidebar mobile menu</span>
        )}
        {openSidebarMobileMenu ? <ExpandLessIcon className='arrow' /> : <ExpandMoreIcon className='arrow' />}
      </Button>
      <Popper
        className={styles.accountSidebarMobileMenuPopper}
        open={openSidebarMobileMenu}
        anchorEl={anchorSidebarMobileMenuRef.current}
        role={undefined}
        transition
        disablePortal
        placement='bottom-start'
        modifiers={[{ name: 'flip', enabled: true }]}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
            <Paper className={styles.accountSidebarMobileMenuPaper}>
              <ClickAwayListener onClickAway={sidebarMobileMenuClose}>
                <MenuList id='sidebar-mobile-menu' className={styles.accountSidebarMobileMenuList}>
                  {items.map(({ icon, label, appPath, target, onClick }) => (
                    <SidebarIcon
                      key={appPath}
                      icon={icon}
                      label={label}
                      appPath={appPath}
                      routerAsPath={routerAsPath}
                      target={target}
                      onClick={onClick}
                    />
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
